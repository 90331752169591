import React, {useState, useEffect} from "react";
import classes from "./Orders.module.css";
import Order from "./Order";
import { useMsal } from "@azure/msal-react";

const MyOrders = () =>{

  const {  accounts } = useMsal();
  const useremail = accounts[0] && accounts[0].username;

    const [orders, setOrders] = useState([]);  

    useEffect(() =>{
      fetchOrdersHandler();
  },[]);
  
    async function  fetchOrdersHandler  () {    
         
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: useremail
    };
  

       const ordersURL = process.env.REACT_APP_GETORDERS_URL;
       const response = await fetch(ordersURL, requestOptions);
       const data = await response.json();     
       setOrders(data);          
    };   

    const ordersList = orders.map(order => 
      <ul className={classes.orders}>
        <Order
          key={order.OrderID} 
          OrderID={order.OrderID} 
          OrderDate={order.OrderDate}  
          Status={order.Status}   
          UserName={order.UserName}
          UserEmail={order.UserEmail}
          ItemsTotal={order.ItemsTotal}                     
        />
        </ul>     
      );

return (  
      <div className={classes.table}>
        {ordersList}
       </div> 
);
};


export default MyOrders;