import { BrowserCacheLocation } from "@azure/msal-browser";


const clientId= process.env.REACT_APP_CLIENTID;
const authority = process.env.REACT_APP_AUTHORITY;
const redirectURL = process.env.REACT_APP_AUTHREDIRECT_URL;



export const msalConfig = {
    auth: {
      clientId: clientId,
      authority: authority, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: redirectURL,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage, // This configures where your cache will be stored
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  // export const graphConfig = {
  //     graphMeEndpoint: "http://localhost:7071/api/SSOMSGraph"
  // };