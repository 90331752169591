
import classes from './Checkout.module.css';
import { useContext, useState } from 'react';
import CartContext from '../Store/cart-context';
import CartItem from './CartItem';
import React from 'react';
import CartForm from './CartForm';
import { NavLink } from "react-router-dom";
import { useMsal } from "@azure/msal-react";


const Cart = () =>{

  const {  accounts } = useMsal();

  const name = accounts[0] && accounts[0].name;

  const useremail = accounts[0] && accounts[0].username;

   const cartCtx = useContext(CartContext);

   var totalAmount = `${cartCtx.totalAmount}`;   
   const hasItems = cartCtx.items.length>0;
  
   const cartItemRemoveHandler = id => {  
     cartCtx.removeItem(id);
    };

    const cartItemAddHandler = (item) => {
        cartCtx.addItem({...item, amount:1});
    };


 

    const [orderMessage, setOrderMessage] = useState([]);  


    const [orderCompleted, setOrderCompleted] = useState(false);

    async function postOrder (formData)        
      {              
          const jsonForm = JSON.stringify({ 
          Status: 'Submitted',
          UserName: name, 
          UserEmail: useremail,  
          UserTitle: formData.title,
          EmployeeNumber: formData.employeeNumber,
          PhoneNumber: formData.phoneNumber,
          DeliveryLocation: formData.deliveryLocation,
          AdditionalInfo: formData.additionalInfo
   
        })
     
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: jsonForm
        };

        const insertOrderURL = process.env.REACT_APP_INSERTORDER_URL;  

        const response = fetch(insertOrderURL, requestOptions)
        . then(
          (response) =>  response.json()      
          );
        const data = await response;      

         postItems(data);

         let bodyOrder = buildOrderBody(data, formData);

         sendHelpDeskEmail(bodyOrder, data);
         sendUserEmail(bodyOrder, data);
         
   };   

   async function postItems (orderid)        
   {            
    var itemsorder=[];
    cartCtx.items.forEach(item => {
      itemsorder.push(
   {
    OrderNumber: orderid,
    ItemID: item.id,
    Quantity: item.amount,
    Size:  item.size,
    UPC:  item.upc,
    Color: item.color,
    Rank: item.rank
   }
    )
    });
 
       const jsonForm = JSON.stringify({   
       Items: itemsorder
     })
    
     const requestOptions = {
         method: 'POST',
         headers: { 'Content-Type': 'application/json' },
         body: jsonForm
     };

     const insertItemsURL = process.env.REACT_APP_INSERTITEMS;

 
     const response = fetch(insertItemsURL, requestOptions)
     . then(
       (response) =>  response.json()      
       );
      await response;   
     
      setOrderMessage(orderid);   
      totalAmount = 0;
     
      setOrderCompleted(true);    
      cartCtx.removeAllItems();  
};   

function buildOrderBody(orderid, formData){
  let cartItems = '';

  cartCtx.items.forEach((item) => {

    let size = '';
    if (item.size)
    size = '| Size: ' + item.size;

    let color = '';
    if (item.color)
    color = '| Color: ' + item.color;

   let rank = '';
   if (item.rank)
    rank = '| Rank: ' + item.rank;

     cartItems +=  '<li> UPC: ' + item.upc + ' | Name: ' + item.name +  '| Quantity: ' + item.amount + size + color + rank + '</li>';     

  });

   let emailbody = 
    'Title: '+ formData.title + '<br>' +
    'Customer: '+ name + '<br>' +
    'Employee Number: '+ formData.employeeNumber + '<br>' +
    'Webstore Order: '+ orderid + '<br>' +
    'Customer Email: '+ useremail + '<br>' +
    'Phone Number: '+ formData.phoneNumber + '<br>' +
    'Delivery Location: '+ formData.deliveryLocation + '<br>' +
    'Items: <br> <ul>'+ cartItems + '</ul><br>' +
    'Additional Information: '+ formData.additionalInfo + '<br>';
  
    return emailbody;

};

async function sendUserEmail (bodyOrder, orderid)  {
  
  let emailbody = 
      'Thank you for submitting an order. This order will be transferred to service desk for processing. <br />'+
      'Order updates will come via email from servicedesk@southmetro.org. Questions about your order? Please call Logistics at 720-989-2440 Ext. 2. <br /><br />';
  emailbody += bodyOrder;

  const sendEmailURL = process.env.REACT_APP_SENDEMAIL_URL;

  const jsonEmail = JSON.stringify({
    To: useremail,
    Subject: 'Order Submitted Id: ' + orderid, 
    Body: emailbody   }); 
 
  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: jsonEmail
  };

  const response =  fetch(sendEmailURL, requestOptions)
  . then(
    (response) =>  response.json()      
    );
   await response;    

}


async function sendHelpDeskEmail (emailbody, orderid)  
{
  const sendEmailURL = process.env.REACT_APP_SENDEMAIL_URL;
  const heldespkEmail = process.env.REACT_APP_HELPDESKEMAILADDRESS;

  const jsonEmail = JSON.stringify({
    To: heldespkEmail,
    Subject: 'Logistics Webstore Order: ' + orderid, 
    Body: emailbody   });
 
  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: jsonEmail
  };

  const response =  fetch(sendEmailURL, requestOptions)
  . then(
    (response) =>  response.json()      
    );
   await response;   
}

  const cartItems = (
  <ul className={classes['cart-items']}>{ 
      cartCtx.items.map((item) => (
      <CartItem 
         key={item.id} 
         id={item.id} 
         upc={item.upc} 
         size= {item.size}  
         name= {item.name}  
         amount = {item.amount}       
         onRemove = {cartItemRemoveHandler.bind(null, item.id)}
         onAdd = {cartItemAddHandler.bind(null, item)}       
      ></CartItem>    

      ))}
  </ul>); 

    return <div className={classes.checkoutcontainer}>
      
       {!orderCompleted && 
       <div>    
         {/* <h2> Items in Cart: </h2> */}

          <div className="navlink">
            <NavLink className="button" to="/">Back to Catalog</NavLink>  
          </div>
     
          <div className={classes.total}>
           <h2>Total items in your cart: {totalAmount}</h2>  
           {cartItems}         
          </div>     
        

          <h3>Employee: {name} Email: {useremail}</h3>
          <CartForm hasItems={hasItems}  postItemsHandler={postOrder}/>     
      </div>    
      }
     {orderCompleted &&
       <div className={classes.confirmation}>
               <NavLink className="button" to="/">Back to Catalog</NavLink>  
               <h2> Thank you! Your order was submitted with ID: {orderMessage}</h2>
       </div>
      
       }
        </div>
     
     


};

export default Cart;
