
import './App.css';
import Header from './Components/Layout/Header'
import React, { Fragment, Suspense, useState } from "react";
import Cart from './Components/Cart/Cart';
import CartProvider from './Components/Store/CartProvider';
import Checkout from './Components/Cart/Checkout';
import {Route, Routes, useNavigate} from 'react-router-dom';
import Orders from './Components/Orders/Orders';
import OrderView from './Components/Orders/OrderView';
import ItemView from './Components/Catalog/Item/ItemView';
import { PageLayout } from "./Components/PageLayout";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";



function App() {

  const [cartIsShown, setCartIsShown] = useState(false);

  const shownCartHandler = () => {
    setCartIsShown(true);
  };

  const navigate = useNavigate();

  const hideCartHandler = () => {
   setCartIsShown(false);  
 };

 const goBackHandler = () => {
  setCartIsShown(false); 
  navigate('/');
};

 const ItemsCatalog = React.lazy(() => import('./Components/Catalog/ItemsCatalog'))

  return (

     
  <Suspense>
    <Fragment>
       <Header  />     
       
       <PageLayout>
          <AuthenticatedTemplate>   
          <Routes>
          <Route path='/' element={        

         <CartProvider>        
        { cartIsShown && <Cart onClose={hideCartHandler} onGoBack={goBackHandler}  />}      
        <main><ItemsCatalog onShowCart={shownCartHandler}/></main>   
           </CartProvider>
        }
      ></Route>
 
    <Route path='/checkout/' element={<CartProvider>  <Checkout />    </CartProvider>}></Route>
  
     <Route path='/orders' element={  <Orders />}></Route>

     <Route path='/order/:id/' element={  <OrderView />}></Route>

     <Route path='/items/:id/:name/' element={       
        <CartProvider>
            <main> <ItemView onShowCart={shownCartHandler}/></main>
         { cartIsShown && <Cart onClose={hideCartHandler}  onGoBack={goBackHandler} />}   
        </CartProvider>
     }
     ></Route> 

    </Routes>


          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>     


          </UnauthenticatedTemplate>


 
    </PageLayout>
    </Fragment>
    </Suspense>  
    
  );
}

export default App;
