
import { useState, useEffect } from 'react';
import React from 'react';
import logslogo from "../../../assets/LogoLog.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'


const ItemCarousel= (props) => {    
  const [multiPic, setMultiPic] = useState(false);
const [images, setImages] = useState([
    {
      ImageURL: logslogo
    }]);

useEffect(() =>{

  async function fetchItemImagesHandler () {          

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body:  props.ItemId     
    };
   
    const itemImagesURL = process.env.REACT_APP_GETITEMSIMAGES_URL;
   
    const response = await fetch(itemImagesURL, requestOptions)
    .then((response) => response.json());
    const data = response;     
   
    if(data.length>0){ 
       setImages(data);     
   }
   if(data.length>1){ 
    setMultiPic(true);
    }
   
   }; 

   fetchItemImagesHandler();
 },[props.ItemId]);



const [currentIndex, setCurrentIndex] = useState(0);

const sliderstyles = {
    position: "relative",
    height: "100%"
  };

const slideStyles = {
    height: "100%",
    width: "100%",
    minHeight: "50rem",   
    backgroundSize: "cover",
    backgroundPosition: "center"   
  };


  const arrowsCont = {
    float: "lef",
    clear:"both",
    width: "100%",
    height: "2rem"
  }

  const leftArrowStyles = {
    color: "black",
    cursor: "pointer",
    display: "inline-block",
    width: "50%",
    textAlign:"right",
    paddingRight: "10px",
  
    fontSize: "23px"
  }
  const rightArrowStyles = {
    color: "black",
    cursor: "pointer",
    display: "inline-block",
    width: "50%",
    textAlign:"left",
    paddingLeft: "10px",    
    fontSize: "23px"
  }


const slideStylesWidthBackground = {
    ...slideStyles,
    backgroundImage: `url(${images[currentIndex].ImageURL})`,
  };

  const goToPrevious = () => {
    const isFirstImage = currentIndex === 0;
    const newIndex = isFirstImage ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastImage = currentIndex === images.length - 1;
    const newIndex = isLastImage ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

return <div style={sliderstyles}>   
  <div style={slideStylesWidthBackground}></div>    
     <div style={arrowsCont} hidden={!multiPic}>
        <div onClick={goToPrevious} style={leftArrowStyles}>
        <FontAwesomeIcon icon={faArrowAltCircleLeft} />
        </div>
        <div onClick={goToNext} style={rightArrowStyles}>
        <FontAwesomeIcon icon={faArrowAltCircleRight} />
        </div>     
      </div>           
    
   

      </div>   
}

export default ItemCarousel;