import React, {useState, useEffect} from "react";
import classes from "./Orders.module.css";
import { NavLink } from "react-router-dom";
import AllOrders from "./AllOrders";
import MyOrders from "./MyOrders";
import { useMsal } from "@azure/msal-react"; 
const Orders = () =>{

   const { accounts } = useMsal();
   const email = accounts[0] && accounts[0].username;
  
  const [userAdmin, setUserAdmint] = useState();

    useEffect(() =>{
      fetchUserAdminHandler();
  },[]);
  
    async function  fetchUserAdminHandler  () {           
       const userAdminURL = process.env.REACT_APP_ISUSERADMIN_URL;

       const requestOptions = {
         method: 'POST',
         headers: { 'Content-Type': 'application/json' },
         body: email    
     };

       const response = await fetch(userAdminURL, requestOptions);
       const data = await response.json();     
       setUserAdmint(data);       
    };   


 

return (<div className={classes.container}>
           <div className={classes.navcontainer}>
              <NavLink className={classes.active} to="/">Home</NavLink>
           </div> 
           <div>
           <div className={classes.title}>               
                <h2>My Orders</h2>       
           </div>  
        
          <div className={classes.container}>     
               <MyOrders/>  
          </div>
          </div>
          {userAdmin &&
           <div>
              <div className={classes.title}>               
                 <h2>All Orders</h2>       
              </div>  
              <div className={classes.container}>     
               <AllOrders/>  
              </div>
          </div>  
           }

    </div>
);
};


export default Orders;