import CartContext from "./cart-context";
import React, { useReducer } from "react";

const defaultCartState = {
  items: [],
  totalAmount: 0
};

const cartReducer = (state, action) =>{

  if(action.type === 'ADD'){

    const existingCartItemIndex = state.items.findIndex(
      (item) => item.upc === action.item.upc      
      );

      const existingCartItem = state.items[existingCartItemIndex];

      let updatedItems;

      if(existingCartItem){
        const updatedItem = {
           ...existingCartItem,
           amount: existingCartItem.amount + action.item.amount
        };
        updatedItems = [...state.items];
        updatedItems[existingCartItemIndex] = updatedItem;
      }
      else {       
        updatedItems = state.items.concat(action.item);
      }

     const updatedtTotalAmount = state.totalAmount +  action.item.amount;

     return {
       items: updatedItems,
       totalAmount: updatedtTotalAmount
     };
  };


  if(action.type === 'REMOVE'){
    
    const existingCartItemIndex = state.items.findIndex(
      (item) => item.id === action.id      
      );

      const existingCartItem = state.items[existingCartItemIndex];

      const updatedtTotalAmount = state.totalAmount - 1;

      let updatedItems;

      if(existingCartItem.amount === 1){
        updatedItems = state.items.filter(item => item.id !== action.id );
      }
      else{
          const updatedItem = {...existingCartItem, amount: existingCartItem.amount-1};
          updatedItems = [...state.items];
          updatedItems[existingCartItemIndex] = updatedItem;
      }

      return {
       items: updatedItems,
       totalAmount: updatedtTotalAmount
      }
  };

  if(action.type === 'REMOVEALL'){
    
    return {
      items: [],
      totalAmount: 0
     }
    }


  return defaultCartState;
};

const CartProvider = props =>{

  const [cartState, dispatchCartAction] = useReducer(cartReducer, defaultCartState);

  const addItemToCartHandler = item => {
      dispatchCartAction({
          type: 'ADD',
          item: item
      });
  };

  const removeItemFromCartHandler = (id) => {
      dispatchCartAction({
          type: 'REMOVE',
          id: id
      });
  };

  const removeAllItemsFromCartHandler = () => {
    dispatchCartAction({
        type: 'REMOVEALL',
        id: 0
    });
};

const cartContext = {
  items: cartState.items,
  totalAmount: cartState.totalAmount,
  addItem: addItemToCartHandler,
  removeItem:removeItemFromCartHandler,
  removeAllItems:removeAllItemsFromCartHandler
};


  return <CartContext.Provider value={cartContext}>
      {props.children}
  </CartContext.Provider>

};


export default CartProvider;