import React, { Fragment } from 'react';

import './Header.module.css';
import logo from "../../assets/getsitelogo.png";
import classes from './Header.module.css';
//  import CartButton from './CartButton';


const Header = (props) =>{
    
    return  <Fragment>
     <header className={classes.header}>
         <img src={logo} alt="SMFR"/>
      <h1 className="headertitle">South Metro Fire Rescue</h1>
      
       {/* <CartButton onClick={props.onShowCart} />      */}

     </header>
    
    </Fragment>
}
export default Header;