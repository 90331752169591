import CartIcon from '../Cart/CartIcon';
import classes from './CartButton.module.css';
import { useContext } from 'react';
import CartContext from '../Store/cart-context';
import React from 'react';

const CartButton = props =>{

    const cartCtx = useContext(CartContext);

    //const totalAmount = `${cartCtx.totalAmount}`;   

  //  const numberOfCartItems = cartCtx.items.length;

    const totalAmount = `${cartCtx.totalAmount}`;   

    // const numberOfCartItems = cartCtx.items.reduce((curNumber, item) => {
    //     return curNumber + item.amount;
    //      }, 0);

return (<button className={classes.button}   onClick={props.onClick}>
        <span className={classes.icon}> <CartIcon /></span>
        <span>Cart</span>
        <span className={classes.badge}> {totalAmount} </span>
       </button>
);
};


export default CartButton;