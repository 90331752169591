import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from "react-bootstrap/Button";

// function refreshPage() {
//     window.location.reload(false);
//   }

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = () => {

        //loginType "popup"
        // if (loginType === "popup") {
        //     instance.loginPopup(loginRequest).catch(e => {
        //         console.log(e);
        //     });
        // }

        instance.loginRedirect(loginRequest);
    }
    return (<div className="ssobox">
    <Button  className="ssobtn btn btn-md btn-primary"  onClick={() => handleLogin()}>Sign in</Button>
       </div>
    );
}