
import ItemViewCarousel from "./ItemViewCarousel";
import { useContext, useState, useEffect } from 'react';
import CartContext from '../../Store/cart-context';
import React from 'react';
import {  useParams, useNavigate } from "react-router-dom";
import useInput from '../../Cart/hooks/use-input.js';
import "./ItemView.css"
import CartButton from '../../Layout/CartButton';

const ItemView = (props) => {
   
  const params = useParams();

   const cartCtx = useContext(CartContext);   

   
   const [sizes, setSizes] = useState([]);
   const [itemDesc, setItemDesc] = useState();  
   const [itemMax, setItemMax] = useState();  
   const [formIsValid, setFormIsValid] = useState(false);  
   const [customSize, setCustomSize] = useState(false);  
 
   const [itemAdded, setItemAdded] = useState(false);
   
/*Form stuff*/
const {
  value: quantityValue, 
  isValid: quantityIsValid,
  hasError: quantityInputHasError,
  valueChangeHandler: quantityChangeHandler,
  inputBlurHandler: quantityBlurHandler,
  reset: resetQuantityInput
 
} = useInput(value => value.trim() > 0);

const {
  value: sizeValue, 
  isValid: sizeIsValid,
  hasError: sizeInputHasError,
  valueChangeHandler: sizeChangeHandler,
  inputBlurHandler: sizeBlurHandler,
  reset: resetSizeInput
} = useInput(value => value.trim() !== '');


const quantityInputClasses = quantityInputHasError ? 'form-control invalid' : 'form-control';
const sizeInputClasses = sizeInputHasError ? 'form-control invalid' : 'form-control';


   useEffect(() =>{
    if(quantityIsValid && sizeIsValid ){     
      setFormIsValid(true);
      }else{
        setFormIsValid(false);
      }     

   }, [quantityIsValid, sizeIsValid]);


   useEffect(() =>{
   if (sizeValue){
       if (sizes){
            if(sizes[sizeValue].Size === 'Custom')
              setCustomSize(true);
            else
              setCustomSize(false);
       }
    }else{
      setCustomSize(false);
    }

    }, [sizeValue]);



    
   const addToCartHandler = event => {
    event.preventDefault();  
  
    if(!quantityIsValid && !sizeIsValid){
      setFormIsValid(false);
      return;
    }
  

    cartCtx.addItem({
      id: sizes[sizeValue].IDetailId, 
      upc: sizes[sizeValue].UPC,
      size: sizes[sizeValue].Size, 
      color: sizes[sizeValue].Color, 
      rank: sizes[sizeValue].Rank, 
      name: params.name,
      amount: Number(quantityValue)      
    });

    showAdded();

   };


  useEffect(() =>{


    async function fetchItemSizesHandler () {     
   
      const itemDetailURL = process.env.REACT_APP_GETITEMDETAIL_URL;      
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: params.id
      };
      
      const response = fetch(itemDetailURL, requestOptions)
      .then((response) => response.json());
      
      const data = await response;     
      let itemoptions = [];

      data.forEach(itemdet => {

     

    let option ='';

    if(itemdet.Size  !== "") option += itemdet.Size;

    if(option !== "" && itemdet.Color) option +=  ' - '; 

    option +=  itemdet.Color;

    if(option !== "" && itemdet.Rank !== "") option += ' - '; 

       option += itemdet.Rank;

      itemoptions.push( {
        IDetailId: itemdet.IDetailId, 
        UPC: itemdet.UPC,
        Size: itemdet.Size,
        Color: itemdet.Color,
        Rank: itemdet.Rank,
        Option: option
      });

      });


      setSizes(itemoptions);      
      
      
     
   };   

   fetchItemSizesHandler();



  async function fetchItemDescHandler () {          
     
          const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: params.id
          };

         const itemDescURL = process.env.REACT_APP_GETITEMDESC_URL;

          const response = fetch(itemDescURL, requestOptions)
          . then((response) => response.json());
          const data = await response;        
          setItemDesc(data.Description);    
          setItemMax(data.Max);
       
        };    

  fetchItemDescHandler();

    },[params.id]);

    const navigate = useNavigate();
    const goBack = () => {
      navigate(-1);
    }


    async function showAdded () {       

     setItemAdded(true);
    }
   

return <div>
<CartButton onClick={props.onShowCart} />   



<div className="itemcontainer"  hidden={itemAdded}>
   
<div className="navlink">
   {/* <NavLink className="button" to="/">Close</NavLink> */}
   <button className="button"  onClick={goBack}>Back to Catalog</button>
</div>
<div className="half">
    <ItemViewCarousel ItemId={params.id} />
</div>   

<div className="half">

<form onSubmit={addToCartHandler}>
     
     <div className="item">
       <h3>{params.name}</h3>
       <h4>{itemDesc}</h4>
      </div>

    <div className="formcontrol">
    <label>Quantity: </label>
       <input 
       className={quantityInputClasses}
       type="number" 
       max={itemMax}
       id="quantity"      
       onBlur={quantityBlurHandler} 
       value={quantityValue} 
       reset={resetQuantityInput}
       onChange={quantityChangeHandler}   />    


{quantityInputHasError && <p>Please enter a valid number</p>}
    </div>

    <div className="formcontrol">
    <label>Select one option: </label>
    <select  
    type="text"
     className={sizeInputClasses}    
     id="size"      
     onBlur={sizeBlurHandler} 
     value={sizeValue}
     reset={resetSizeInput}
     onChange={sizeChangeHandler}    
      
    >
      <option value =""></option>
      {sizes.map((item, index ) => <option key={index} value={index} >{item.Option}</option>)}
    </select>

    {quantityInputHasError && <p>Please select a size</p>}

    { (customSize) && <p>Please add details for custom in Additional Info at Checkout</p>}
    

    </div>
    <div className="formcontrol">
        {/* <button type="submit" onClick={props.onShowCart} disabled={!formIsValid} >+ Add to cart</button>     */}
        <button type="submit" disabled={!formIsValid} >+ Add to cart</button>  
    </div>
    </form>
</div>    

</div>

<div className="itemcontainer"  hidden={!itemAdded}>
<div className="itemadded">
<h2>Item added to the cart! </h2>
    <h3>{params.name}</h3>
    <div className="addedbtns">

    <button className="button"  onClick={goBack}>Back to Catalog</button>
    </div>
</div>
</div>
</div>
}

export default ItemView;