import classes from './CartItem.module.css';
import React from "react";

const CartItem = (props) => {

  
 var option = "";
 if(props.size || props.color || props.rank) {
 option = ' - ';
 
 if(props.size) option += props.size;

 if(props.size && props.color) option +=  ' - '; 

 if(props.color)option += props.color;

 if(props.color && props.rank ) option += ' - '; 

 if(props.rank)option += props.rank;
 }
 


  return (
    <li className={classes['cart-item']}>
      <div>      
      <h3>{props.id} - {props.upc} - {props.name}  {option}</h3>
       
      </div>
      <div className={classes.actions}>
      <span className={classes.amount}>x {props.amount}</span>   
       <button onClick={props.onRemove}>-</button>
          {/*<button onClick={props.onAdd}>+</button> */}
      </div>
    </li>
  );
};

export default CartItem;
