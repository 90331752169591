import classes from './Order.module.css';
import React from 'react';
import { NavLink } from "react-router-dom";

const Order = props => {

   const openorder = "/order/"+props.OrderID;

   function getShortDate(strDate){
      var strSplitDate = String(strDate).split(' ');
      var date = new Date(strSplitDate[0]);
    
      var dd = date.getDate();
      var mm = date.getMonth() + 1; //January is 0!
  
      var yyyy = date.getFullYear();
      if (dd < 10) {
          dd = '0' + dd;
      }
      if (mm < 10) {
          mm = '0' + mm;
      }
      date =  mm + "/" + dd + "/" + yyyy;
      return date.toString();
  }

return(
      <li>
   <div className={classes.order}>
      <div className={classes.name}> Order ID: {props.OrderID}</div>  
      <div className={classes.name}> Order Date: {getShortDate(props.OrderDate)}</div>  
      <div className={classes.name}> Order Status: {props.Status}</div>  
      <div className={classes.name}> User Name: {props.UserName}</div>
      {/* <div className={classes.name}> Total Items: {props.ItemsTotal}</div>          */}
      <div className={classes.name}> <NavLink className={classes.button} to={openorder} >Open</NavLink></div>      
   </div> 
   </li> 
);

}


export default Order;