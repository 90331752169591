import { useState } from "react";

const useInput = (validateValue) => {
  const[enteredValue, setEnteredValue] = useState('');
  const[isTouched, setIsTouched] = useState('');

  const valueIsValid = validateValue(enteredValue);
  const hasError= !valueIsValid && isTouched;

  const valueChangeHandler = (event) => {
    
    if(event.target.id === 'employeeNumber'){     
      event.target.value = event.target.value.slice(0,10);    
    }   
    if(event.target.id === 'phoneNumber'){     
      event.target.value = event.target.value.slice(0,10);    
    }  
    setEnteredValue(event.target.value);     
  };

  const inputBlurHandler = (event) => {
    setIsTouched(true);        
  };

  const reset = () => {
    setEnteredValue('');
    setIsTouched(false);
  }

return {
  value: enteredValue, 
  isValid: valueIsValid,
  hasError, 
  valueChangeHandler, 
  inputBlurHandler,
  reset
};

};


export default useInput;