import Modal from '../UI/Modal';
import classes from './Cart.module.css';
import { useContext } from 'react';
import CartContext from '../Store/cart-context';
import CartItem from './CartItem';
import React from 'react';
import { NavLink } from "react-router-dom";

const Cart = props =>{
   const cartCtx = useContext(CartContext);

   const totalAmount = `${cartCtx.totalAmount}`;   
   const hasItems = cartCtx.items.length>0;
  
   const cartItemRemoveHandler = (upc) => {  
     cartCtx.removeItem(upc);
    };

    const cartItemAddHandler = (item) => {
        cartCtx.addItem({...item, amount:1});
    };

  const cartItems = (
  <ul className={classes['cart-items']}>{ 
      cartCtx.items.map((item) => (
      <CartItem 
         key={item.id} 
         id={item.id} 
         upc={item.upc} 
         name= {item.name}  
         size= {item.size}  
         color= {item.color}  
         rank= {item.rank}  
         amount = {item.amount}       
         onRemove = {cartItemRemoveHandler.bind(null, item.id)}
         onAdd = {cartItemAddHandler.bind(null, item)}       
      ></CartItem>    

      ))}
  </ul>);

  const checkouturl = "/checkout/";

    return <Modal onClose={props.onClose}>
     <div className={classes.closebutton} >
     <button    onClick={props.onClose}>Close Cart</button>
      <button    onClick={props.onGoBack}>Back to Catalog</button>
      </div>
      { hasItems && <div >
     

       <div className={classes.total}>
           <span>Total items in your cart: {totalAmount}</span>           
       </div>

       {cartItems}

  
       <NavLink className={classes.active}  to = {checkouturl}   onClick={props.onClose}>Checkout</NavLink>
            
      </div>
       }
      { !hasItems && <div >
       <h2> There are no items in the cart</h2>
       </div>
       }
    </Modal>


};

export default Cart;
