import { useEffect,useState, Suspense } from 'react';
import React from 'react';
import { NavLink, useParams } from "react-router-dom";
import classes from "./OrderView.module.css";
// import OrderSummary from "./OrderSummary";

const OrderView = () => {
   
  const params = useParams();   

   const [orderSummary, setOrderSummary] = useState();  
   const [orderItems, setOrderItems] = useState();  
   
  useEffect(() =>{

    async function fetchOrderDetailHandler () {        
  
    const orderSummaryURL = process.env.REACT_APP_GETORDERSUMMARY_URL;
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: params.id
      };
      
      const response = fetch(orderSummaryURL, requestOptions)
      .then((response) => response.json());      
      const data = await response;  
    
      setOrderSummary(data);       
    };   

      async function fetchOrderItemsHandler () {           

        const orderItemsURL = process.env.REACT_APP_GETORDERITEMS_URL;
  

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: params.id
        };
        
        const response = fetch(orderItemsURL, requestOptions)
        .then((response) => response.json());
        
        const data = await response;  
      
        setOrderItems(data);      
     };   
    

   fetchOrderDetailHandler();

   fetchOrderItemsHandler();

    },[params.id]);
 
    const OrderSummary = React.lazy(() => import('./OrderSummary'))



return <Suspense><div className={classes.ordercont}>

<div className={classes.navlink}>
   <NavLink className={classes.button} to="/orders">Close</NavLink>
</div>

<div className={classes.order}>
<div className={classes.ordertitle}>Order Number: {params.id}</div>  
     
     <div className={classes.order}>
       <h3>Order Detail</h3>      
       <OrderSummary orderSummary={orderSummary} orderItems={orderItems}/>
       </div>
</div>
</div>   </Suspense> 

}

export default OrderView;