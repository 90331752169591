
import React, {useState, useEffect} from "react";
import useInput from './hooks/use-input';
import classes from './CartForm.css';

const CartForm = (props) => {

const [locations, setLocations] = useState([]);

  useEffect(() =>{
    fetchLocationsHandler();
},[]);

async function  fetchLocationsHandler  () {      

const locationsURL = process.env.REACT_APP_GETLOCATIONS_URL;

const response = await fetch(locationsURL)
.then((response) => response.json());
const data = response;     

if(data.length>0){ 
   setLocations(data);     
}
  }
 
  const [formInput, setFormInput] = useState({
    title:'',
    employeeNumber: '',
    phoneNumber: '',
    deliveryLocation: '',
    additionalInfo: ''
   });

   //inputs
const {
  value: employeeTitleValue, 
  isValid: employeeTitleIsValid,
  hasError: employeeTitleInputHasError,
  valueChangeHandler: employeeTitleChangeHandler,
  inputBlurHandler: employeeTitleBlurHandler,
  reset: resetEmployeeTitleInput
} = useInput(value => value.trim() !== '');
 
   const {
    value: employeeNumber, 
    isValid: employeeNumberIsValid,
    hasError: employeeNumberInputHasError,
    valueChangeHandler: employeeNumberChangeHandler,
    inputBlurHandler: employeeNumberBlurHandler,
    reset: resetEmployeeNumberInput
  } = useInput(value => value.trim() !== '');
 
  const {
    value: phoneNumber, 
    isValid: phoneNumberIsValid,
    hasError: phoneNumberInputHasError,
    valueChangeHandler: phoneNumberChangeHandler,
    inputBlurHandler: phoneNumberBlurHandler,
    reset: resetPhoneNumberInput
  } = useInput(value => value.trim() !== '');  
  
  const {
    value: deliveryLocation, 
    isValid: deliveryLocationIsValid,
    hasError: deliveryLocationInputHasError,
    valueChangeHandler: deliveryLocationChangeHandler,
    inputBlurHandler: deliveryLocationBlurHandler,
    reset: resetDeliveryLocationInput
  } = useInput(value => value.trim() !== '');

  
const additionalInfoChange = (event) =>{
  setFormInput(
   {
     ...formInput,
     additionalInfo: event.target.value  
   });
  };

  let formIsValid = false;

  if (props.hasItems && employeeTitleIsValid && employeeNumberIsValid && phoneNumberIsValid && deliveryLocationIsValid) {
    formIsValid = true;
  }


  const submitHandler = (event) =>{
    event.preventDefault();

    if (!formIsValid) {
      return;
    }
  
 const arrayform = {    
    title: employeeTitleValue,
    employeeNumber: employeeNumber,
    phoneNumber: phoneNumber,
    deliveryLocation: deliveryLocation,
    additionalInfo: formInput.additionalInfo
    };
  
  props.postItemsHandler(arrayform);
    resetEmployeeTitleInput();
    resetEmployeeNumberInput();
    resetPhoneNumberInput();  
    resetDeliveryLocationInput();
    
  }  
   
     const employeeTitleInputClasses = employeeTitleInputHasError ? 'form-control invalid' : 'form-control';
     const employeeNumberInputClasses = employeeNumberInputHasError ? 'form-control invalid' : 'form-control';
     const phoneNumberInputClasses = phoneNumberInputHasError ? 'form-control invalid' : 'form-control';
     const deliveryLocationInputClasses = deliveryLocationInputHasError ? 'form-control invalid' : 'form-control';

  return (
  <form onSubmit={submitHandler}>
   <div className='control-group'>
     <div className={employeeTitleInputClasses}>
      <label htmlFor='enteredTitle'>Title: </label>
      <input 
      type="text" 
          id="employeeTitle"
           onChange={employeeTitleChangeHandler} 
           onBlur={employeeTitleBlurHandler} 
           value={employeeTitleValue}
           reset={resetEmployeeTitleInput}
           maxLength="50"
      />
      {employeeTitleInputHasError && <p className="error-text">Title must not be empty</p> }
     </div>
    
     <div className={employeeNumberInputClasses}>
       <label>Employee Number: </label>
       <input type="number"           
           id="employeeNumber"
           onChange={employeeNumberChangeHandler} 
           onBlur={employeeNumberBlurHandler} 
           value={employeeNumber}
           reset={resetEmployeeNumberInput}
        
       />
      {employeeNumberInputHasError && <p className="error-text">Employee Number must not be empty</p> }
    </div>
  
    <div className={phoneNumberInputClasses} >
    <label>Phone Number: </label>
    <input type="number"           
           id="phoneNumber"
           onChange={phoneNumberChangeHandler} 
           onBlur={phoneNumberBlurHandler} 
           value={phoneNumber}
           reset={resetPhoneNumberInput}      
        
    />
       {phoneNumberInputHasError && <p className="error-text">Phone Number must not be empty</p> }
   </div>

   <div className='control-group'>
   <div className={deliveryLocationInputClasses}>
      <label>Delivery Location: </label>
      <select type="text"               
       id="deliveryLocation"
       onChange={deliveryLocationChangeHandler} 
        onBlur={deliveryLocationBlurHandler} 
        value={deliveryLocation}
         reset={resetDeliveryLocationInput}
         >

       <option value =""></option>
      {locations.map((item, index ) => <option key={index} value={item} >{item}</option>)}
      </select>

          {deliveryLocationInputHasError && <p className="error-text">Delivery Location must not be empty</p> }
    </div>
    </div>
    <div className='form-control'>
    <label>Additional Info: </label>
    <textarea rows="3" 
         type="textarea" 
         onChange={additionalInfoChange} 
         maxLength="200"
    />
    </div>
 
    </div>
    <div className='form-actions'>
    {/* <button type="button" className={classes['button close']}  >Close</button> */}
    <button type="Submit" disabled={!formIsValid} className={classes['button order']} >Place Order</button>
   </div>
  

   </form>
  );

};

export default CartForm;
