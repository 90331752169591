import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";  
import { useMsal } from "@azure/msal-react"; 
/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const PageLayout = (props) => {
    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();
    const name = accounts[0] && accounts[0].name;
    return (
        <>
            <div className="authbar">               
            { isAuthenticated ?  <h3> Welcome {name } <SignOutButton /></h3> : <p>Please sign in. <SignInButton /></p> }
            </div>          
            
            {props.children}
        </>
    );
};